<template>
  <div class="pageContol">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">数据统计</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()">学员统计</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">学习详情</a>
        </span>
      </div>
      <div class="framePage-body">
        <div style="margin:0 14px 20px">
          <span>姓名：{{userName}}</span>
          <span style="margin:0 40px;">身份证号：{{idcard}}</span>
          <span style="margin:0 20px;">电话：{{mobile}}</span>
          <span style="margin:0 20px;">班级名称：{{projectName}}</span>
        </div>
        <div class="operationControl flexdc" style="align-items:flex-start">
          <div class="searchbox">
            <div title="课程名称" class="searchboxItem ci-full">
              <span class="itemLabel">课程名称:</span>
              <el-input
                v-model="courseName"
                type="text"
                size="small"
                placeholder="请输入课程名称"
                clearable
              />
            </div>
            <div title="课节名称" class="searchboxItem ci-full">
              <span class="itemLabel">课节名称:</span>
              <el-input
                v-model="kpointName"
                type="text"
                size="small"
                placeholder="请输入课节名称"
                clearable
              />
            </div>
            <div title="学习状态" class="searchboxItem ci-full">
              <span class="itemLabel">学习状态:</span>
              <el-select v-model="complateState" clearable placeholder="请选择" size="small">
                <el-option
                  v-for="item in complateStatelist"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
            <el-button type="primary" class="bgc-bv" round @click="getData()">查询</el-button>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                width="100"
                fixed
                :index="indexMethod"
              />

              <el-table-column
                label="课程名称"
                align="left"
                show-overflow-tooltip
                prop="courseName"
              />
              <el-table-column
                label="课节名称"
                align="left"
                show-overflow-tooltip
                prop="kpointName"
              />
              <el-table-column
                label="视频总时长"
                align="right"
                width="100"
                show-overflow-tooltip
                prop="kpointDuration"
              >
                <template slot-scope="scope">
                  <span>{{getTime(scope.row.kpointDuration)}}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="课节学时"
                align="right"
                show-overflow-tooltip
                prop="kpointLessonNum"
                width="100px"
              />
              <el-table-column
                label="学习状态"
                align="center"
                show-overflow-tooltip
                prop="completeState"
                width="100px"
              >
                <template
                  slot-scope="scope"
                >{{$setDictionary("LEARNINGSTATE", scope.row.completeState)}}</template>
              </el-table-column>
              <el-table-column
                label="开始学习时间"
                align="left"
                show-overflow-tooltip
                prop="createTime"
                width="200px"
              >
                <template slot-scope="scope">
                  <span v-if="scope.row.createTime">{{scope.row.createTime | moment}}</span>
                  <span v-else>--</span>
                </template>
              </el-table-column>
              <el-table-column label="完成时间" align="left" show-overflow-tooltip width="200px">
                <template slot-scope="scope">
                  <span v-if="scope.row.completeTime">{{scope.row.completeTime | moment}}</span>
                  <span v-else>--</span>
                </template>
              </el-table-column>
              <el-table-column
                label="最后观看时间"
                align="left"
                show-overflow-tooltip
                prop="lastTime"
                width="200px"
              >
                <template slot-scope="scope">
                  <span v-if="scope.row.lastTime">{{scope.row.lastTime | moment}}</span>
                  <span v-else>--</span>
                </template>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import moment from "moment";
export default {
  name: "operate/learningDetails",
  components: {
    Empty,
    PageNum
  },
  mixins: [List],
  data() {
    return {
        courseName:'',//课程名称
kpointName:'',//课节名称
      catalogName: "",
      projectName: "",
      idcard: "",
      mobile: "",
      userName: "",
      complateState: "",
      complateStatelist: [],
    };
  },
  created() {
    this.getLearningstateList();
    this.getTableHeight();
    this.getData();
  },
  computed: {},
  methods: {
    getData(pageNum = 1) {
      let params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        courseName:this.courseName,
        kpointName:this.kpointName,
        complateState: this.complateState,
        userId: this.$route.query.userId,
        projectId: this.$route.query.projectId,
      };
      this.doFetch({
        url: "/biz/report/user/page-RBStudy-list",
        params,
        pageNum
      });
      this.getListInfo({
        url: "/biz/report/user/userInfoRB",
        params: params
      });
    },
    getListInfo(params) {
      this.$post(params.url, params.params).then(res => {
        if (res.status == "0") {
          const data = res.data || {};
          this.userName = data.userName;
          this.mobile = data.mobile;
          this.idcard = data.idcard;
          this.projectName = data.projectName;
        }
      });
    },
    getLearningstateList() {
      const complateStatelist = this.$setDictionary("LEARNINGSTATE", "list");
      const list = [];
      for (const key in complateStatelist) {
        if(key!="40"){
 list.push({
          value: key,
          label: complateStatelist[key]
        });
        }
       
      }

      this.complateStatelist = list;
    },

    getTime(val) {
      if (!val) {
        return "0秒";
      }
      let h = parseInt(val / 60 / 60);
      let m = parseInt((val % 3600) / 60);
      let s = val % 60;
      if (h < 9) {
        h = "0" + h;
      }
      if (m < 9) {
        m = "0" + m;
      }
      if (s < 9) {
        s = "0" + s;
      }
      return (
        (h != "00" ? h + "时" : "") +
          (m != "00" ? m + "分" : "") +
          (s != "00" ? s + "秒" : "") || ""
      );
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35) * 16;
      if (opDom) {
        tHeight -= 34 + 40 + 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    }
  }
};
</script>
<style lang="less" scope>
.searchboxfl {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start !important;
}
